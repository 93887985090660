/**
 * Helper Class for the screen stuff
 * - Fetch if its mobile screen or not
 */
export class ScreenHelper {
    public isMobile: boolean = false;

    public detectScreenSize() {
        const self = this;

        if (document && document.documentElement) {
            const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            if (width < 768) {
                self.isMobile = true;
            } else if (width >= 768) {
                self.isMobile = false;
            }

            let res;
            window.onresize = function() {
                // to fix the problem that window.onresize fires only twice we use a setTimeout
                if (res) {
                    clearTimeout(res);
                }

                res = setTimeout(function() {
                    if (document && document.documentElement) {
                        const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                        if (w < 768) {
                            self.isMobile = true;
                        } else if (w >= 768) {
                            self.isMobile = false;
                        }
                    }
                }, 100);
            };
        }
    }
}
