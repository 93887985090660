
import { OfferBase } from './OfferBase';

import { Component } from 'vue-property-decorator';
import GeneralBox from '@/components/configurator/helpers/general/GeneralBox.vue';
import OfferModal from '@/components/configurator/offer/OfferModal.vue';
import OfferConstructionMethodChooser from '@/components/configurator/offer/OfferConstructionMethodChooser.vue';
import { Getter } from 'vuex-class';
import { Product } from '@/models/configurator/product';

@Component({
    name: 'OfferMinimal',
    components: {
        GeneralBox,
        OfferModal,
        OfferConstructionMethodChooser,
    },
})
export default class OfferMinimal extends OfferBase {
    private isModalActive: boolean = false;
    @Getter('configuratorResult/selectedProduct') private selectedProduct: Product;

    private toggleModal() {
        this.isModalActive = !this.isModalActive;
    }

    get numberOfOptions() {
        let count: number = 0;
        this.result.topics.forEach((t) => {
            count += t.options.length;
        });
        return count;
    }
}
