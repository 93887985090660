
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { EditOptionDto } from '@/models/editor/dto/EditOptionDto';
import { Option } from '@/models/configurator/option';
import OptionForm from '@/components/editor/option-editor/OptionForm.vue';
import ConfirmModal from '@/components/prine/ConfirmModal.vue';
import Price from '@/models/configurator/price';
import { editorOptionWebservice } from '@/webservices/editor/option';

@Component({
    name: 'EditOptionModal',
    components: {
        OptionForm,
        ConfirmModal,
    },
})
export default class EditOptionModal extends Vue {
    @Action('editorOption/saveOption') private saveOption;
    @Action('configuratorTopic/fetchAddonTopics') private fetchAddonTopics;

    @Prop()
    private option: Option;
    @Prop()
    private isTopicScopeIntern: boolean;
    private optionDto: EditOptionDto;
    private showConfirmModal: boolean = false;
    private changes: boolean = false;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;
    private images_file: File[] = [];

    private created() {
        this.optionDto = {
            id: this.option.id,
            text: this.option.text,
            description: this.option.description,
            price: {
                biopool: this.option.price.biopool,
                naturpool: this.option.price.naturpool,
                schwimmteich: this.option.price.schwimmteich,
                schwimmteich_diy: this.option.price.schwimmteich_diy,
            } as Price,
            option_type_id: this.option.option_type_id,
            price_type_id: this.option.price_type_id,
            topic_id: this.option.topic_id,
            construction_method_ids: this.option.construction_methods.map((cm) => cm.id),
            product_ids: this.option.products.map((cm) => cm.id),
            published: !!this.option.published_at,
            scope_id: this.option.scope_id,
            option_images: this.option.option_images,
        };
    }

    private async saveValidatedOption(optionToSave: EditOptionDto) {
        const updatedOption = await this.saveOption(optionToSave);
        await this.saveOptionImages(updatedOption);
        await this.fetchAddonTopics();
        this.$emit('toggleEditOptionModal');
    }

    private async saveOptionImages(updatedOption: EditOptionDto) {
        const formData = new FormData();
        for (const file of this.images_file) {
            formData.append('images[]', file, file.name);
        }
        if (this.images_file.length > 0) {
            await editorOptionWebservice.saveOptionImages(updatedOption, formData);
        }
    }

    private toggleEditOptionModal() {
        if (this.changes) {
            this.showConfirmModal = true;
        } else {
            this.$emit('toggleEditOptionModal');
        }
    }

    private confirmModal(confirm: boolean) {
        if (confirm) {
            this.$emit('toggleEditOptionModal');
        } else {
            this.showConfirmModal = false;
        }
    }
}
