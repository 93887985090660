
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/models/common/user';
import { userWebservice } from '@/webservices/common/user';

@Component({
    name: 'ForgotPassword',
})
export default class ForgotPassword extends Vue {
    // Data
    private user: User = {
        id: 0,
        role_id: 4,
        phone_number: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        new_password: '',
    };
    private disabled: boolean = false;
    private errorMsg: string = '';
    private emailSent: boolean = false;

    // Methods
    private sendEmail() {
        if (!this.validateEmail(this.user.email)) {
            this.errorMsg = 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
            return 0;
        }

        this.disabled = true;

        userWebservice
            .forgotPassword(this.user.email)
            .then((response) => {
                this.errorMsg = '';
                this.emailSent = true;
            })
            .catch((error) => {
                this.errorMsg = 'Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut';
                this.disabled = false;
                console.log(error);
            });
    }

    private validateEmail(email: string) {
        /* tslint:disable-next-line:max-line-length */
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }
}
