
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { EditTopicDto } from '@/models/editor/dto/EditTopicDto';
import { Topic } from '@/models/configurator/topic';
import { TopicImage } from '@/models/configurator/topicImage';
import ConfirmModal from '@/components/prine/ConfirmModal.vue';
import { Scope } from '@/models/common/scope';
import Multiselect from 'vue-multiselect';
import UploadImages from '@/components/prine/UploadImages.vue';
import { editorTopicWebservice } from '@/webservices/editor/topic';

@Component({
    name: 'EditTopicModal',
    components: {
        ConfirmModal,
        Multiselect,
        UploadImages,
    },
})
export default class EditTopicModal extends Vue {
    @Action('editorTopic/saveTopic') private saveTopic;
    @Action('configuratorTopic/fetchAddonTopics') private fetchAddonTopics;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;
    @Getter('scope/scopes') private scopes: Scope[];

    @Prop()
    private topic: Topic;
    private topicDto: EditTopicDto = {
        id: 0,
        title: '',
        description: '',
        required: false,
        multianswer: false,
        published: false,
        scope_id: null,
        topic_images: [],
    };
    private selectedScope: Scope | null = null;
    private showConfirmModal: boolean = false;
    private changes: boolean = false;
    private saving: boolean = false;
    private validated: boolean = false;
    private images_file: File[] = [];

    private created() {
        this.topicDto = {
            id: this.topic.id,
            title: this.topic.title,
            description: this.topic.description,
            required: this.topic.required,
            multianswer: this.topic.multianswer,
            published: !!this.topic.published_at,
            scope_id: this.topic.scope_id,
            topic_images: this.topic.topic_images,
        };
        const scope = this.scopes.find((item) => {
            return this.topicDto.scope_id === item.id;
        });
        if (scope) {
            this.selectedScope = scope;
        } else {
            this.selectedScope = null;
        }
    }

    private async saveTopicDto() {
        if (this.validate()) {
            this.saving = true;
            const updatedTopic = await this.saveTopic(this.topicDto);
            await this.saveTopicImages(updatedTopic);
            await this.fetchAddonTopics();
            this.$emit('toggleEditTopicModal');
        }
    }

    private async saveTopicImages(updatedTopic: EditTopicDto) {
        const formData = new FormData();
        for (const file of this.images_file) {
            formData.append('images[]', file, file.name);
        }
        if (this.images_file.length > 0) {
            await editorTopicWebservice.saveTopicImages(updatedTopic, formData);
        }
    }

    private validate() {
        this.validated = true;
        return (this.$refs.titleField as any).checkHtml5Validity() && this.isScopeValid;
    }

    private toggleEditTopicModal() {
        if (this.changes) {
            this.showConfirmModal = true;
        } else {
            this.$emit('toggleEditTopicModal');
        }
    }

    private confirmModal(confirm: boolean) {
        if (confirm) {
            this.$emit('toggleEditTopicModal');
        } else {
            this.showConfirmModal = false;
        }
    }

    private updateScope() {
        this.changes = true;
        if (this.selectedScope) {
            this.topicDto.scope_id = this.selectedScope.id;
        } else {
            this.topicDto.scope_id = null;
        }
    }

    get isScopeValid() {
        return this.topicDto.scope_id != null;
    }

    get topic_images_path() {
        return this.topicDto.topic_images?.map((el: TopicImage) => process.env.VUE_APP_BACKEND_URL + el.path);
    }

    private deleteTopicImages() {
        this.topicDto.topic_images = [];
    }
}
