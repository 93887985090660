
import { OfferBase } from './OfferBase';

import { Component } from 'vue-property-decorator';
import { Topic } from '@/models/configurator/topic';
import { Option } from '@/models/configurator/option';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import DetailModalButton from '@/components/configurator/helpers/DetailModalButton.vue';
import { OptionImage } from '@/models/configurator/optionImage';

@Component({
    name: 'Offer',
    components: {
        DetailModalButton,
    },
})
export default class OfferAddonsOptions extends OfferBase {
    private showModal: boolean = false;

    private removeOption(topic: Topic, option: Option) {
        // Iterate and replace or add it
        const topicIndex = this.result.topics.findIndex((el) => {
            return el.id === topic.id;
        });

        const optionIndex = this.result.topics[topicIndex].options.findIndex((el) => {
            return el.id === option.id;
        });

        if (topicIndex !== -1 && optionIndex !== -1) {
            // If it exists, remove it
            this.result.topics[topicIndex].options.splice(optionIndex, 1);
            this.$store.commit('configuratorResult/setSelectedTopic', this.result.topics[topicIndex]);
            Snackbar.open({
                message: option.text + ' wurde entfernt',
                position: 'is-top',
                queue: false,
            });
        }
    }

    private anyOptionIsSelected() {
        let optionSelected: boolean = false;
        this.result.topics.forEach((topic) => {
            if (topic.options.length > 0) {
                optionSelected = true;
                return;
            }
        });
        return optionSelected;
    }

    private toggleDetailModal() {
        this.showModal = !this.showModal;
    }

    private option_images_path(option: Option) {
        return option.option_images?.map((el: OptionImage) => process.env.VUE_APP_BACKEND_URL + el.path);
    }
}
