
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { mapGetters } from 'vuex';
import { Photo } from '@/models/preAnalysis/photo';
import { photoWebservice } from '@/webservices/preAnalysis/photo';
import Swiper, { swiper, swiperSlide } from 'vue-awesome-swiper';
import { Utils } from '@/helpers/Utils';

@Component
export default class Introduction extends Vue {
    private photos: Photo[] = new Array<Photo>();
    private utils: Utils = new Utils();

    // Data
    private swiperOption = {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        speed: 600,
        // preventIntercationOnTransition:true,
        // allowSlidePrev:false,
        // allowSlideNext:false,
        noSwiping: true, // needed to disable swiping
        noSwipingClass: 'swiper-no-swiping',
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: false,
            type: 'bullets',
        },
    };

    get swiper() {
        if (this.$refs.photoSwiper) {
            return ((this.$refs.photoSwiper as any) as Swiper).swiper;
        }
    }

    private mounted() {
        this.loadAllPhotos();
        if (this.swiper) {
            this.swiper.autoplay.start();
        }
    }

    private loadAllPhotos() {
        photoWebservice
            .getAllPhotos()
            .then((photos) => {
                this.photos = photos.filter((photo) => {
                    return photo.type === 'All';
                });

                this.photos = this.utils.shuffleArray(this.photos);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private start() {
        this.$store.commit('preAnalysisWizard/setCurrentTab', 1);
    }
}
