import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Result } from '@/models/configurator/result';
import { Option } from '@/models/configurator/option';
import { Topic } from '@/models/configurator/topic';
import { Dimension } from '@/models/configurator/dimension';
import { Material } from '@/models/configurator/material';
import { ConstructionMethod } from '@/models/configurator/constructionMethod';
import { Product } from '@/models/configurator/product';
import { Form } from '@/models/configurator/form';

Vue.use(Vuex);

interface State {
    result: Result;
}

const mutations: MutationTree<State> = {
    setResult: (currentState, result) => {
        currentState.result = result;
    },

    setDimension: (currentState, dimension: Dimension) => {
        currentState.result.dimension = dimension;
    },

    setMaterial: (currentState, material: Material) => {
        currentState.result.material = material;
    },

    setProduct: (currentState, product: Product) => {
        currentState.result.product = product;
    },

    setConstructionMethod: (currentState, constructionMethod: ConstructionMethod) => {
        currentState.result.constructionMethod = constructionMethod;
    },

    setForm: (currentState, form: Form) => {
        currentState.result.form = form;
    },

    setSelectedTopic: (currentState, newTopic: Topic) => {
        const index = currentState.result.topics.findIndex((topic) => {
            return topic.id === newTopic.id;
        });

        if (index !== -1) {
            Vue.set(currentState.result.topics, index, newTopic);
        } else {
            Vue.set(currentState.result.topics, currentState.result.topics.length, newTopic);
        }
    },

    addTopic: (currentState, newTopic: Topic) => {
        currentState.result.topics.push(newTopic);
    },

    setBasePrice: (currentState, value: number) => {
        currentState.result.base_price = value;
    },

    setTotalOptionsPrice: (currentState, value: number) => {
        currentState.result.total_options_price = value;
    },

    setTotalPrice: (currentState, value: number) => {
        currentState.result.total_price = value;
    },

    setVisualizationImages: (currentState, visualizationImages: string[]) => {
        currentState.result.visualizationImages = visualizationImages;
    },

    resetAllAddons: (currentState) => {
        currentState.result.topics = [];
    },

    resetState(currentState) {
        currentState.result = {
            id: 0,
            when: new Date(),
            firstname: '',
            lastname: '',
            phone_number: '',
            email: '',
            follow_up: false,
            comment: '',
            file_name: '',
            constructionMethod: {} as ConstructionMethod,
            dimension: {} as Dimension,
            material: {} as Material,
            product: {} as Product,
            topics: Array<Topic>(),
            base_price: 0,
            total_options_price: 0,
            total_price: 0,
            form: null,
            visualizationImages: [],
        };
    },
};

const actions: ActionTree<State, any> = {};

const getters = {
    result(currentState: State): Result {
        return currentState.result;
    },

    selectedTopics(currentState: State): Topic[] {
        return currentState.result.topics;
    },

    selectedTopicById(currentState: State) {
        return (topicId: number) => {
            return currentState.result.topics.find((topic) => {
                return topic.id === topicId;
            });
        };
    },

    selectedTopicByTitle(currentState: State) {
        return (topicTitle: string) => {
            return currentState.result.topics.find((topic) => {
                return topic.title === topicTitle;
            });
        };
    },

    selectedDimension(currentState: State): Dimension {
        return currentState.result.dimension;
    },

    selectedMaterial(currentState: State): Material {
        return currentState.result.material;
    },

    selectedProduct(currentState: State): Product {
        return currentState.result.product;
    },

    selectedConstructionMethod(currentState: State): ConstructionMethod {
        return currentState.result.constructionMethod;
    },

    selectedForm(currentState: State): Form | null {
        return currentState.result.form;
    },

    visualizationImages(currentState: State): string[] {
        return currentState.result.visualizationImages;
    },
};

const state: State = {
    result: {
        id: 0,
        when: new Date(),
        firstname: '',
        lastname: '',
        phone_number: '',
        email: '',
        follow_up: false,
        comment: '',
        file_name: '',
        constructionMethod: {} as ConstructionMethod,
        dimension: {} as Dimension,
        material: {} as Material,
        product: {} as Product,
        topics: Array<Topic>(),
        base_price: 0,
        total_options_price: 0,
        total_price: 0,
        form: null,
        visualizationImages: [],
    },
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
