
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import { Getter } from 'vuex-class';

import { mapGetters } from 'vuex';
import { Topic } from '@/models/preAnalysis/topic';
import { Option } from '@/models/preAnalysis/option';
import { Result } from '@/models/preAnalysis/result';

@Component({})
export default class QuestionBox extends Vue {
    // Data
    private selectedOption: Option | null = null;
    private isModalActive: boolean = false;

    // Props
    @Prop() private topic: Topic;

    // Vuex Getters
    @Getter('preAnalysisTopic/activeTopic') private activeTopic: Topic;
    @Getter('preAnalysisResult/values') private values: number[];
    @Getter('preAnalysisResult/result') private result: Result;
    @Getter('preAnalysisTopic/selectedOptions') private selectedOptions: Option[];
    @Getter('preAnalysisTopic/topics') private topics: Topic[];

    @Watch('result')
    private onValuesChanged() {
        // If the options in the result are empty, remove the selected option
        if (this.result.options.length === 0) {
            this.selectedOption = null;
        }
    }

    // Methods
    private optionSelected(topic: Topic, option: Option) {
        this.selectedOption = option;

        const data = {
            option,
            topic_id: topic.id,
        };

        this.$store.commit('preAnalysisResult/addOption', data);

        // When the user has answered, open the next question
        if (topic.id !== this.topics.length) {
            this.setActiveTopic(this.topics[topic.id]);
        }
    }

    private isOptionSelected(option: Option) {
        return this.result.options.find((answeredOption) => {
            if (answeredOption.id === option.id) {
                this.selectedOption = option;
                return true;
            } else {
                return false;
            }
        });
    }

    private setActiveTopic(topic: Topic) {
        this.$store.commit('preAnalysisTopic/setActiveTopic', topic);
    }

    private isAnswered() {
        return this.selectedOption != null;
    }

    private toggleInfoModal(event) {
        // event.preventDefault();
        // event.stopImmediatePropagation();
        this.isModalActive = !this.isModalActive;
    }
}
