import { render, staticRenderFns } from "./Selection.vue?vue&type=template&id=a59105f6&scoped=true"
import script from "./Selection.vue?vue&type=script&lang=ts"
export * from "./Selection.vue?vue&type=script&lang=ts"
import style0 from "./Selection.vue?vue&type=style&index=0&id=a59105f6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a59105f6",
  null
  
)

export default component.exports