
import * as echarts from 'echarts';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { mapGetters } from 'vuex';
import { Option } from '@/models/preAnalysis/option';

@Component
export default class RadarChart extends Vue {
    // Data
    private chartRadar: any = null;

    // Vuex Getters
    @Getter('preAnalysisResult/values') private values;

    @Watch('values')
    private onValuesChanged() {
        this.initEcharts();
    }

    // Mounted is called initially
    private mounted() {
        this.initEcharts();
    }

    // Methods
    private initEcharts() {
        const htmlRadarChart = document.getElementById('echartsRadar') as HTMLDivElement;

        if (htmlRadarChart != null) {
            this.chartRadar = echarts.init(htmlRadarChart);
            this.setRadarChartOptions();
        }
    }

    private setRadarChartOptions() {
        const option = {
            tooltip: {},
            legend: {
                data: ['Data 1'],
            },
            radar: {
                // shape: 'circle',
                name: {
                    textStyle: {
                        color: '#000',
                        backgroundColor: '#fff',
                        borderRadius: 3,
                        padding: [5, 5],
                        fontFamily: 'Frutiger-Bold',
                    },
                },
                splitNumber: 4,
                indicator: [
                    { name: 'Tiere', max: 4 },
                    { name: 'Pflanzenwachstum', max: 4 },
                    { name: 'Klarheit des Wassers', max: 4 },
                    { name: 'Ablagerungen', max: 4 },
                    { name: 'Algenauftreten', max: 4 },
                    { name: 'Technische Installationen', max: 4 },
                    { name: 'Wasserattraktionen', max: 4 },
                    { name: 'Pflegeaufwand', max: 4 },
                ],
            },
            series: [
                {
                    name: 'Smart Salamander',
                    type: 'radar',
                    itemStyle: {
                        color: '#333',
                    },
                    lineStyle: {
                        color: '#888',
                    },
                    areaStyle: {
                        color: '#EEDB36',
                    },
                    data: [
                        {
                            value: this.values, // use state directly
                            name: 'Benutzer',
                        },
                    ],
                },
            ],
        };

        // use result item and data specified to show chart
        if (this.chartRadar != null) {
            this.chartRadar.setOption(option);
        }

        this.addEvent(window, 'resize', (event) => {
            this.chartRadar.resize();
        });

        setTimeout(() => {
            this.chartRadar.resize();
        }, 100);
    }

    // addEvent method
    private addEvent(object, type, callback) {
        if (object == null || typeof object === 'undefined') {
            return;
        }
        if (object.addEventListener) {
            object.addEventListener(type, callback, false);
        } else if (object.attachEvent) {
            object.attachEvent('on' + type, callback);
        } else {
            object['on' + type] = callback;
        }
    }
}
