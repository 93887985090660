
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ConstructionMethod } from '@/models/configurator/constructionMethod';
import { Material } from '@/models/configurator/material';
import { Product } from '@/models/configurator/product';

@Component({
    name: 'Intro',
})
export default class Intro extends Vue {
    @Getter('configuratorConstructionMethod/constructionMethods') private constructionMethods: ConstructionMethod[];
    @Getter('configuratorMaterial/materials') private materials: Material[];
    @Getter('configuratorProduct/products') private products: Product[];

    private start() {
        this.$store.commit('configuratorWizard/setCurrentTab', 1);
    }

    get selectedMaterial() {
        return this.$store.getters['configuratorResult/selectedMaterial'];
    }

    set selectedMaterial(material: Material) {
        this.$store.commit('configuratorResult/setMaterial', material);
    }

    get selectedConstructionMethod() {
        return this.$store.getters['configuratorResult/selectedConstructionMethod'];
    }

    set selectedConstructionMethod(constructionMethod: ConstructionMethod) {
        // this.removeNonSupportedOptions();

        // Reset all addons
        this.$store.commit('configuratorResult/resetAllAddons');

        // Set the selected construction method
        this.$store.commit('configuratorResult/setConstructionMethod', constructionMethod);

        // Special handling for DIY
        if (constructionMethod.name === 'diy') {
            const materialHolz = this.materials.find((m) => m.name === 'holz');
            const productSchwimmteich = this.products.find((p) => p.name === 'schwimmteich');

            // Always select holz
            if (materialHolz) {
                this.$store.commit('configuratorResult/setMaterial', materialHolz);
                this.selectedMaterial = materialHolz;
            }

            // If it's diy it needs to select Schwimmteich, its only supported there currently
            if (productSchwimmteich) {
                this.$store.commit('configuratorResult/setProduct', productSchwimmteich);

                this.$router.push({
                    path: this.$router.currentRoute.fullPath,
                    query: {
                        product: productSchwimmteich.title,
                    },
                });
            }
        }
    }

    private selectConstructionMethodDiy() {
        const diy = this.constructionMethods.find((cm) => {
            return cm.name === 'diy';
        });

        if (diy) {
            this.selectedConstructionMethod = diy;
        }

        // Go to next page
        this.start();
    }

    private selectConstructionMethodProfi() {
        const profi = this.constructionMethods.find((cm) => {
            return cm.name === 'profi';
        });

        if (profi) {
            this.selectedConstructionMethod = profi;
        }

        // Go to next page
        this.start();
    }
}
