
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import { Getter, Mutation } from 'vuex-class';

import { mapGetters } from 'vuex';
import { Photo } from '@/models/preAnalysis/photo';

@Component
export default class PhotoSelection extends Vue {
    @Getter('preAnalysisResult/photosLiked') private photosLiked: Photo[];
    @Getter('preAnalysisResult/photosDisliked') private photosDisliked: Photo[];

    @Mutation('preAnalysisResult/removeLike') private removeLike;
    @Mutation('preAnalysisResult/removeDislike') private removeDislike;
}
