
import { OfferBase } from './OfferBase';

import { Component } from 'vue-property-decorator';
import DetailModalButton from '@/components/configurator/helpers/DetailModalButton.vue';
import OfferAddonsOptions from '@/components/configurator/offer/OfferAddonsOptions.vue';

@Component({
    name: 'Offer',
    components: {
        OfferAddonsOptions,
        DetailModalButton,
    },
})
export default class Offer extends OfferBase {
    private isOpen: boolean = false;
}
