
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { mapGetters } from 'vuex';
import { User } from '@/models/common/user';
import { Result } from '@/models/configurator/result';
import { resultWebservice } from '@/webservices/configurator/result';
import ResultDetails from './ResultDetails.vue';

import OfferSelection from '@/components/configurator/offer/OfferSelection.vue';
import OfferAddons from '@/components/configurator/offer/OfferAddons.vue';
import { PriceHelper } from '@/components/configurator/helpers/price/PriceHelper';
import PoolVisualizerImproved from '@/components/configurator/visuals/PoolVisualizerImproved.vue';
import ResetButton from '@/components/configurator/helpers/ResetButton.vue';

@Component({
    name: 'ResultPage',
    components: {
        ResultDetails,
        OfferSelection,
        OfferAddons,
        PoolVisualizerImproved,
        ResetButton,
    },
})
export default class ResultPage extends Vue {
    // Data
    public genBtnOptions = {
        text: 'PDF herunterladen',
        url: '#',
        target: '',
        icon: '',
        loading: false,
        generated: false,
    };

    // public followUp:boolean = false

    public errorMsg: string = '';

    protected disabled: boolean = false;
    protected isModalActive: boolean = false;
    protected emailSent: boolean = false;
    protected sendingEmail: boolean = false;

    private priceHelper: PriceHelper = new PriceHelper();

    @Getter('account/currentUser') private currentUser: User;
    @Mutation('account/setCurrentUser') private setCurrentUser;

    @Getter('configuratorResult/result') private result: Result;
    @Getter('preAnalysisResult/result') private pAResult: Result;

    @Getter('configuratorResult/visualizationImages') private visualizationImages: string[];

    private generatePDF() {
        if (this.genBtnOptions.generated) {
            return 0;
        }
        this.storeTheResultsAndGenPdf(false);
    }

    private storeTheResultsAndGenPdf(followUp: boolean) {
        // If followUp is true Validate the user info
        if ((followUp && !this.validateUserInput()) || this.genBtnOptions.loading) {
            return 0;
        }

        this.genBtnOptions.text = 'PDF wird generiert..';
        this.genBtnOptions.icon = '';
        this.genBtnOptions.loading = true;
        this.sendingEmail = true;

        const user: User = {
            id: 0,
            role_id: 0,
            firstname: this.result.firstname,
            lastname: this.result.lastname,
            email: this.result.email,
            phone_number: this.result.phone_number,
            password: '',
            new_password: '',
        };

        this.setCurrentUser(user);

        const resultCopy = JSON.parse(JSON.stringify(this.result));
        const pAResultCopy = JSON.parse(JSON.stringify(this.pAResult));

        // recalculate all options payment_price
        resultCopy.topics.forEach((topic) => {
            topic.options.forEach((option) => {
                option.payment_price = this.priceHelper.calculateOptionPrice(option, resultCopy);
            });
        });

        resultWebservice.store(resultCopy, pAResultCopy).then((res) => {
            setTimeout(() => {
                this.sendingEmail = false;
                this.emailSent = true;
                this.editGenBtnOptions(res.file_name, user.email);
            }, 1000);
        });
    }

    private editGenBtnOptions(fileName, user_email) {
        // tslint:disable-next-line:max-line-length
        this.genBtnOptions.url =
            process.env.VUE_APP_API_URL +
            'configurator/v1/results/Salamander-Naturbad-Konfigurator?file_name=' +
            fileName;

        if (user_email !== '') {
            this.genBtnOptions.url += '&email=' + user_email;
        } else {
            this.genBtnOptions.url += '&email=guests';
        }

        this.genBtnOptions.target = '_blank';
        this.genBtnOptions.loading = false;
        this.genBtnOptions.generated = true;
        this.genBtnOptions.text = 'Download';
        this.genBtnOptions.icon = 'download';
    }

    private toggleInfoModal(event) {
        this.isModalActive = !this.isModalActive;
        this.emailSent = false;
        this.errorMsg = '';
    }

    private validateUserInput() {
        if (this.result.firstname === '') {
            // this.errorMsg = 'Plaese enter your Name';
            this.errorMsg = 'firstname';
            return 0;
        } else if (this.result.lastname === '') {
            this.errorMsg = 'lastname';
            return 0;
        } else if (this.result.phone_number === '') {
            this.errorMsg = 'phone_number_empty';
            return 0;
        } else if (!this.validateEmail(this.result.email)) {
            this.errorMsg = 'email';
            return 0;
        } else {
            return true;
        }
    }

    private validateEmail(email) {
        // tslint:disable-next-line:max-line-length
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    private validatePhoneNumber(phone_number) {
        const re = /^(?:(?:|0{1,2}|\+{0,2})41(?:|\(0\))|0)([1-9]\d)(\d{3})(\d{2})(\d{2})$/;
        return re.test(phone_number.toLowerCase());
    }
}
