
import { Component, Prop, Vue } from 'vue-property-decorator';
import CreateOptionModal from '@/components/editor/option-editor/CreateOptionModal.vue';
import { Topic } from '@/models/configurator/topic';
import { Getter } from 'vuex-class';

@Component({
    name: 'CreateOptionModalButton',
    components: {
        CreateOptionModal,
    },
})
export default class CreateOptionModalButton extends Vue {
    @Prop()
    private topic: Topic;
    @Prop()
    private isTopicScopeIntern: boolean;
    private showModal: boolean = false;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;

    private toggleCreateOptionModal() {
        this.showModal = !this.showModal;
    }
}
