
import { User } from '@/models/common/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { userWebservice } from '@/webservices/common/user';
@Component({
    name: 'Login',
})
export default class Login extends Vue {
    // Data
    private user: User = {
        id: 0,
        role_id: 4,
        phone_number: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        new_password: '',
    };
    private disabled: boolean = false;
    private errorMsg: string = '';
    private isLoading: boolean = false;

    // Methods
    private login() {
        if (this.user.email === '' || this.user.password === '') {
            this.errorMsg = 'Die Emailadresse oder das Passwort fehlt.';
            return 0;
        }

        this.isLoading = true;
        if (this.user.email && this.user.password) {
            userWebservice
                .login(this.user.email, this.user.password)
                .then((response) => {
                    this.errorMsg = '';
                    this.$store.commit('account/setApiToken', response.data);

                    userWebservice.getUserInfo().then((getUserInfoResponse) => {
                        this.$store.commit('account/setCurrentUser', getUserInfoResponse.data);
                        this.$router.push({ path: '/' });
                        this.isLoading = false;
                    });
                })
                .catch((error) => {
                    this.errorMsg = 'Das Passwort oder der Benutzername ist nicht korrekt';
                    this.isLoading = false;
                });
        }
    }
}
