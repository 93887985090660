
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { ProposalMatch } from '@/models/preAnalysis/proposalmatch';
import { Option } from '@/models/preAnalysis/option';
import { Result } from '../../models/configurator/result';
import { User } from '@/models/common/user';

@Component({
    name: 'Navbar',
})
export default class Navbar extends Vue {
    protected isActive: boolean = false;
    @Getter('preAnalysisResult/proposalMatches') private proposalMatches: ProposalMatch[];
    @Getter('preAnalysisResult/selectedOptions') private selectedOptions: Option[];
    @Getter('configuratorResult/result') private result: Result;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;
    @Getter('account/currentUser') private current_user: User;

    private toggleNav() {
        this.isActive = !this.isActive;
    }

    private hideMenu() {
        setTimeout(() => {
            this.isActive = false;
        }, 100);
    }

    private get mainProposal() {
        if (this.proposalMatches.length > 0 && this.selectedOptions.length >= 4) {
            const mainProposal = this.proposalMatches[0];
            return mainProposal.title + ' ' + mainProposal.match_percentage + '%';
        } else {
            return 'Voranalyse noch kein Resultat';
        }
    }
}
