var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('general-box',[_c('template',{slot:"headerLeft"},[_c('span',{staticClass:"topic-title"},[_vm._v("Aktueller Preis")])]),_c('template',{slot:"content"},[(
                    _vm.selectedProduct.name === 'schwimmteich' ||
                        _vm.selectedProduct.name === 'badebrunnen' ||
                        _vm.selectedProduct.name === 'sitzbadeteich'
                )?_c('div',{staticClass:"column is-12"},[_c('OfferConstructionMethodChooser')],1):_vm._e(),_c('div',{staticClass:"column is-12"},[(_vm.totalPrice !== 0)?_c('div',{staticClass:"columns is-multiline is-vcentered"},[_c('div',{staticClass:"column is-5"},[(_vm.totalPrice != 0)?_c('span',{staticClass:"title-total-price is-pulled-right"},[_vm._v(_vm._s(_vm.FORMAT_PRICE(_vm.totalPrice))+".-")]):_vm._e()]),_c('div',{staticClass:"column is-7"},[_c('span',{staticClass:"title-base-price"},[_vm._v("Basispreis "),_c('u',[_vm._v(_vm._s(_vm.result.product.title))]),_vm._v(" "+_vm._s(_vm.FORMAT_PRICE(_vm.basePrice))+" CHF")]),_c('br'),(_vm.totalOptionPrice !== 0)?_c('span',{staticClass:"title-base-price"},[_vm._v("Total "),_c('a',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleModal()}}},[_vm._v("Optionen ("+_vm._s(_vm.numberOfOptions)+")")]),_vm._v(" "+_vm._s(_vm.FORMAT_PRICE(_vm.totalOptionPrice))+" CHF ")]):_vm._e()])]):_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-12"},[_vm._v(" Hier wird der Preis deines zusammengestellten Naturbades dargestellt. ")])])]),(
                    _vm.selectedProduct.name !== 'schwimmteich' &&
                        _vm.selectedProduct.name !== 'badebrunnen' &&
                        _vm.selectedProduct.name !== 'sitzbadeteich'
                )?_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-12 has-text-centered"},[_c('i',{staticClass:"fas fa-info-circle"}),_vm._v(" Ausführung nur über Fachfirma möglich ")])]):_vm._e()])],2),(_vm.isModalActive)?_c('offer-modal',{on:{"toggleModal":_vm.toggleModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }