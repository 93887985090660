
import { Component, Vue } from 'vue-property-decorator';
import { ConstructionMethod } from '@/models/configurator/constructionMethod';
import { Getter } from 'vuex-class';
import { Material } from '@/models/configurator/material';
import { Product } from '@/models/configurator/product';
import { Dimension } from '@/models/configurator/dimension';

@Component({
    name: 'OfferConstructionMethodChooser',
})
export default class OfferConstructionMethodChooser extends Vue {
    @Getter('configuratorConstructionMethod/diy') private diy: ConstructionMethod;
    @Getter('configuratorConstructionMethod/profi') private profi: ConstructionMethod;

    @Getter('configuratorResult/selectedMaterial') private selectedMaterial: Material;
    @Getter('configuratorMaterial/materials') private materials: Material[];

    @Getter('configuratorDimension/dimensions') private dimensions: Dimension[];

    @Getter('configuratorResult/selectedProduct') private selectedProduct: Product;

    get selectedConstructionMethod() {
        return this.$store.getters['configuratorResult/selectedConstructionMethod'];
    }

    set selectedConstructionMethod(constructionMethod: ConstructionMethod) {
        // this.removeNonSupportedOptions();
        this.$store.commit('configuratorResult/setConstructionMethod', constructionMethod);

        if (constructionMethod.name === 'diy') {
            if (this.selectedProduct.name === 'badebrunnen') {
                const kunststoff = this.materials.find((m) => m.name === 'kunststoff');

                if (kunststoff) {
                    this.$store.commit('configuratorResult/setMaterial', kunststoff);
                }
                const mini = this.dimensions.find((d) => d.name === 'badebrunnen_kunststoff_mini');

                if (mini) {
                    this.$store.commit('configuratorResult/setDimension', mini);
                }
            } else {
                const holz = this.materials.find((m) => m.name === 'holz');
                // Always select holz
                if (holz) {
                    this.$store.commit('configuratorResult/setMaterial', holz);
                }
            }
        }
    }
}
