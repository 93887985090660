import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { dimensionWebservice } from '@/webservices/configurator/dimension';
import { Dimension } from '@/models/configurator/dimension';

Vue.use(Vuex);

interface State {
    dimensions: Dimension[];
}

const mutations: MutationTree<State> = {
    setDimensions: (currentState, dimensions) => (currentState.dimensions = dimensions),

    resetState(currentState) {
        currentState.dimensions = Array<Dimension>();
    },
};

const actions: ActionTree<State, any> = {
    async fetchDimensions({ commit }) {
        try {
            let dimensions = await dimensionWebservice.getAll();
            // sort dimensions by order
            dimensions = dimensions.sort((a: Dimension, b: Dimension) => {
                return a.order - b.order;
            });
            commit('setDimensions', dimensions);
        } catch (error) {
            console.error('Failed loading in the Webservice: [' + error.status + ']: ' + error.message);
            // Initialize the topics empty
            commit('setDimensions', []);
        }
    },
};

const getters = {
    dimensions(currentState: State) {
        return currentState.dimensions;
    },
};

const state: State = {
    dimensions: Array<Dimension>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
