
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Action, Getter } from 'vuex-class';
import { Topic } from '@/models/configurator/topic';
import { Option } from '@/models/configurator/option';

import DetailModalButton from '../DetailModalButton.vue';
import EditTopicModalButton from '@/components/editor/topic-editor/EditTopicModalButton.vue';

import { Product } from '@/models/configurator/product';
import { ConstructionMethod } from '@/models/configurator/constructionMethod';

import { StoreOptionRadio } from './StoreOptionRadio';
import { StoreOptionCheckbox } from './StoreOptionCheckbox';
import { StoreOptionNumber } from './StoreOptionNumber';

import * as R from 'ramda';
import { StoreOption } from '@/components/configurator/helpers/topic/StoreOption';
import { PriceHelper } from '@/components/configurator/helpers/price/PriceHelper';
import { Result } from '@/models/configurator/result';

import { AddonDataHelper } from './AddonDataHelper';
import DeleteTopicButton from '@/components/editor/topic-editor/DeleteTopicButton.vue';
import CreateOptionModalButton from '@/components/editor/option-editor/CreateOptionModalButton.vue';
import EditOptionModalButton from '@/components/editor/option-editor/EditOptionModalButton.vue';
import DeleteOptionButton from '@/components/editor/option-editor/DeleteOptionButton.vue';
import draggable from 'vuedraggable';
import { Scope } from '@/models/common/scope';
import { TopicImage } from '@/models/configurator/topicImage';
import { OptionImage } from '@/models/configurator/optionImage';
import DetailModal from '@/components/configurator/helpers/DetailModal.vue';

@Component({
    components: {
        DeleteOptionButton,
        EditOptionModalButton,
        CreateOptionModalButton,
        DeleteTopicButton,
        EditTopicModalButton,
        DetailModalButton,
        DetailModal,
        draggable,
    },
})
export default class TopicBox extends Vue {
    // Data
    private selectedTopic: Topic = {} as Topic;
    private isModalActive: boolean = false;
    private priceHelper: PriceHelper = new PriceHelper();
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;

    // Vuex
    @Action('configuratorTopic/swapAddonTopicOptions') private swapAddonTopicOptions;
    @Action('configuratorTopic/setAddonTopicOptions') private setAddonTopicOptions;

    @Getter('configuratorTopic/activeTopic') private activeTopic: Topic;
    @Getter('configuratorTopic/addonTopics') private addonTopics: Topic[];
    @Getter('configuratorResult/selectedTopics') private selectedTopics: Topic[];
    @Getter('editorTopic/isReOrderActive') private isReOrderActive: number;
    @Getter('configuratorResult/result') private result: Result;
    @Getter('scope/internScopeId') private internScopeId: number;

    @Getter('configuratorResult/selectedProduct') private selectedProduct: Product;
    @Getter('configuratorResult/selectedConstructionMethod') private selectedConstructionMethod: ConstructionMethod;

    @Getter('configuratorResult/selectedTopicById')
    private selectedTopicById: (topicId: number) => Topic | undefined;

    // Add here all store stategies which are used to store options
    // See strategy pattern: https://sourcemaking.com/design_patterns/strategy
    private storeStrategies: StoreOption[] = [
        new StoreOptionRadio(),
        new StoreOptionCheckbox(),
        new StoreOptionNumber(),
    ];

    private hasBeenStored: boolean = false;

    private addonDataHelper: AddonDataHelper = new AddonDataHelper();

    private showDetailModal: boolean = false;

    // Props
    @Prop({
        type: Object,
        default: () => {
            return {
                id: 0,
                title: '',
                description: '',
                multianswer: true,
                required: false,
                options: [],
                topic_type_id: 2,
                published_at: new Date(),
            };
        },
    })
    private topic: Topic;

    @Prop({
        type: Boolean,
        default: () => {
            return false;
        },
    })
    private alwaysOpen: boolean;

    @Prop()
    private topicIndex: number;
    @Prop()
    private displayUp: boolean;
    @Prop()
    private displayDown: boolean;

    private mounted() {
        this.loadTopic();
    }

    private isOptionActive(option: Option) {
        if (this.isLoggedIn) {
            return true;
        }
        const isDefault = option.default_products?.find((p) => p.id === this.result.product.id) !== undefined;

        // Only for the Schwimmteich check if the Construction method is correctly set
        if (this.result.product.name === 'schwimmteich') {
            if (this.result.constructionMethod.name === 'diy') {
                // Additionally check the sizes for the Aushub
                if (option.id === 35 && this.result.dimension.name !== 'small') {
                    return false;
                }

                if (option.id === 36 && this.result.dimension.name !== 'medium') {
                    return false;
                }

                if (option.id === 37 && this.result.dimension.name !== 'large') {
                    return false;
                }

                return this.isCMActive(option) && this.isProductActive(option);
            } else {
                return this.isCMActive(option) && this.isProductActive(option);
            }
        } else {
            return this.isProductActive(option);
        }
    }

    private isCMActive(option: Option) {
        return (
            option.construction_methods?.find((el) => {
                return el.name === this.selectedConstructionMethod.name;
            }) !== undefined
        );
    }

    private isProductActive(option: Option) {
        return (
            option.products?.find((el) => {
                return el.name === this.selectedProduct.name;
            }) !== undefined
        );

        // Test with Ramda
        // return !R.isNil(R.find(R.propEq('name', this.selectedProduct.name))(option.products));
    }

    private loadTopic() {
        const fetchedTopic = this.selectedTopicById(this.topic.id);

        if (fetchedTopic !== undefined) {
            this.selectedTopic = fetchedTopic;
        } else {
            // Clone the current topic
            this.selectedTopic = JSON.parse(JSON.stringify(this.topic));
            this.selectedTopic.options = [];
        }
    }

    private setActiveTopic(topic: Topic) {
        this.$store.commit('configuratorTopic/setActiveTopic', topic);
    }

    private store({ target }, option: Option) {
        // Find depending on the option store strategy name the correct implementation
        const storeStrategy = R.find(R.propEq('optionType', option.option_type!.name), this.storeStrategies);

        // Call the store method
        if (!R.isNil(storeStrategy)) {
            storeStrategy.store(this.selectedTopic, option, target, this.$store);
            this.hasBeenStored = true;
        }
    }

    private clearSelectedOptions(topic: Topic) {
        this.selectedTopic.options = [];
        this.$store.commit('configuratorResult/setSelectedTopic', this.selectedTopic);
    }

    private openDetailModal() {
        console.log('detailed modal will be opened');
        this.toggleDetailModal();
    }

    private toggleDetailModal() {
        this.showDetailModal = !this.showDetailModal;
    }

    private checkboxOptionValue(optionValue: Option) {
        return this.selectedTopic.options?.find((o) => o.id === optionValue.id) !== undefined;
    }

    private radioOptionValue(optionValue: Option) {
        // console.log('Options of this selected topic: ', this.selectedTopic.title);

        // if (this.selectedTopic.options?.length === 0) {
        //     console.log('Search for option ', optionValue.text);

        //     let foundOption;

        //     // There are now answers yet to this question, use default value
        //     this.addonTopics.forEach((t) => {
        //         t.options.forEach((o) => {
        //             console.log('COMPARE: ' + o.id + ' => ' + optionValue.id);
        //             if (o.id === optionValue.id && o.default_products.find((p) => this.result.product.id)) {
        //                 foundOption = o;
        //             }
        //         });
        //     });
        //     console.log(foundOption);
        //     return foundOption;
        // } else {
        // There are already answers, return them
        if (this.selectedTopic.options?.find((o) => o.id === optionValue.id)) {
            return this.selectedTopic.options?.find((o) => o.id === optionValue.id);
        } else {
            return null;
        }
        // }
    }

    private inputOptionValue(option: Option) {
        const selected = this.selectedTopic.options?.find((o) => o.id === option.id);
        return selected !== undefined ? selected.value : 0;
    }

    private toggleTopicDetailModal() {
        this.isModalActive = !this.isModalActive;
    }

    private get totalPrice() {
        let sumPrice = 0;

        // Nothing todo if there is not yet anything selected, just return 0
        if (R.isEmpty(this.selectedTopic)) {
            return sumPrice;
        }

        this.selectedTopic.options.map((option) => {
            sumPrice += this.priceHelper.calculateOptionPrice(option, this.result);
        });

        return sumPrice;
    }

    private get showTopic() {
        return this.topic.options.find((option) => {
            const hasProducts = option.products.find((product) => {
                return this.result.product.id === product.id;
            });

            const hasConstructionMethod = option.construction_methods.find((constructionMethod) => {
                return this.result.constructionMethod.id === constructionMethod.id;
            });

            if (this.result.product.name === 'schwimmteich') {
                return hasProducts && hasConstructionMethod;
            } else {
                return hasProducts;
            }
        });
    }

    private get options() {
        return this.topic.options;
    }

    private set options(value) {
        this.setAddonTopicOptions({
            topic_id: this.topic.id,
            options: value,
        });
    }

    private reOrderOptionUp(index) {
        this.swapAddonTopicOptions({
            topic_id: this.topic.id,
            sourceIndex: index,
            targetIndex: index - 1,
        });
    }

    private reOrderOptionDown(index) {
        this.swapAddonTopicOptions({
            topic_id: this.topic.id,
            sourceIndex: index,
            targetIndex: index + 1,
        });
    }

    private getScopeClass(scope_id) {
        return scope_id === this.internScopeId ? 'internal-tag' : 'external-tag';
    }

    get topic_images_path() {
        return this.topic.topic_images?.map((el: TopicImage) => process.env.VUE_APP_BACKEND_URL + el.path);
    }

    private option_images_path(option: Option) {
        return option.option_images?.map((el: OptionImage) => process.env.VUE_APP_BACKEND_URL + el.path);
    }
}
