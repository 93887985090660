
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import { mapGetters } from 'vuex';
import { Photo } from '@/models/preAnalysis/photo';

import AnalysisQuestions from '../analysis/AnalysisQuestions.vue';
import AnalysisPhotos from '../analysis/AnalysisPhotos.vue';
import EmailTheResults from '../analysis/followup/EmailTheResults.vue';
import Introduction from '../introduction/Introduction.vue';
import { Option } from '@/models/preAnalysis/option';
import { Topic } from '@/models/preAnalysis/topic';

@Component({
    name: 'WizardPreAnalysis',
    components: {
        AnalysisQuestions,
        AnalysisPhotos,
        Introduction,
        EmailTheResults,
    },
})
export default class Wizard extends Vue {
    @Getter('preAnalysisTopic/topics') private topics: Topic[];
    @Getter('preAnalysisResult/selectedOptions') private selectedOptions: Option[];
    @Getter('preAnalysisResult/photosLiked') private photosLiked: Photo[];
    @Getter('preAnalysisResult/photosDisliked') private photosDisliked: Photo[];

    get userNotLikedAndDisliked() {
        return this.photosDisliked.length === 0 && this.photosLiked.length === 0;
    }

    get currentTab() {
        return this.$store.state.preAnalysisWizard.currentTab;
    }

    set currentTab(value: number) {
        this.$store.commit('preAnalysisWizard/setCurrentTab', value);
    }
}
