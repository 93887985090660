
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Result } from '@/models/configurator/result';
import { Topic } from '@/models/configurator/topic';

import GeneralBox from '@/components/configurator/helpers/general/GeneralBox.vue';

import * as R from 'ramda';
import { visualizationWebservice } from '@/webservices/configurator/visualization';
import { VisualizationBasic } from '@/models/configurator/visualizationBasic';
import { Option } from '@/models/preAnalysis/option';

const poolPath = './img/visuals';

@Component({
    name: 'PoolVisualizerImproved',
    components: {
        GeneralBox,
    },
})
export default class PoolVisualizerImproved extends Vue {
    @Getter('configuratorResult/result') private result: Result;
    private visualizationsBasic: VisualizationBasic[] = [];

    private async mounted() {
        this.visualizationsBasic = await visualizationWebservice.getAll();
    }

    @Watch('baseImage')
    @Watch('overlayImage')
    @Watch('overlayImageDouche')
    @Watch('overlayImageStone')
    private changed() {
        this.$store.commit('configuratorResult/setVisualizationImages', [
            this.baseImage,
            this.overlayImageDeck,
            this.overlayImageDouche,
            this.overlayImageStone,
        ]);
    }

    private get baseImage() {
        let foundVisualization;
        let foundMoreMatches = false;

        this.visualizationsBasic.forEach((visualizationBasic) => {
            let hasBasic = false;

            if (this.result.product.name === 'naturpool') {
                // For the naturpool additionally check the form
                hasBasic =
                    visualizationBasic.product_id === this.result.product.id &&
                    visualizationBasic.material_id === this.result.material.id &&
                    visualizationBasic.form_id === this.result.form?.id;
            } else {
                hasBasic =
                    visualizationBasic.product_id === this.result.product.id &&
                    visualizationBasic.material_id === this.result.material.id;
            }

            let hasEdging: Option | undefined;
            let hasEntry: Option | undefined;
            let hasCover: Option | undefined;

            // Check additional options
            this.result.topics.forEach((topic) => {
                topic.options.forEach((option) => {
                    if (option.id === visualizationBasic.option_edging_id) {
                        hasEdging = option;
                    }

                    if (option.id === visualizationBasic.option_entry_id) {
                        hasEntry = option;
                    }

                    if (option.id === visualizationBasic.option_cover_id) {
                        hasCover = option;
                    }

                    if (hasBasic && hasEdging && hasEntry && hasCover) {
                        foundVisualization = visualizationBasic;
                        foundMoreMatches = true;
                    } else if (hasBasic && hasEdging && hasEntry && visualizationBasic.option_cover_id === null) {
                        if (!foundVisualization || !foundMoreMatches) {
                            foundVisualization = visualizationBasic;
                        }
                    }
                });
            });
        });
        return foundVisualization?.filename ? `${poolPath}/${foundVisualization.filename}.jpg` : undefined;
    }

    private get overlayImageDeck() {
        let overlayPath = '';

        //  Zwei Beckenseiten 2m breit
        // Check additional options
        this.result.topics.forEach((topic) => {
            topic.options.forEach((option: Option) => {
                // console.log('edging: ' + option.id + ' => ' + visualizationBasic.option_edging_id);
                if (option.id === 49) {
                    if (this.result.product.name === 'schwimmteich') {
                        overlayPath = 'Deck_L_Schwimmteich.png';
                    } else {
                        overlayPath = 'Deck_L.png';
                    }
                } else if (option.id === 47) {
                    if (this.result.product.name === 'schwimmteich') {
                        overlayPath = 'Deck_Short2m_Schwimmteich.png';
                    } else {
                        overlayPath = 'Deck_Short2m_Default.png';
                    }
                } else if (option.id === 48) {
                    if (this.result.product.name === 'biopool' || this.result.product.name === 'naturpool') {
                        overlayPath = 'Deck_L_short1m.png';
                    } else {
                        overlayPath = 'deck_long.png';
                    }
                }
            });
        });

        // As default for naturpool and biopool always use the overlay
        if (overlayPath === '') {
            if (this.result.product.name === 'biopool' || this.result.product.name === 'naturpool') {
                overlayPath = 'Deck_Short_Default.png';
            }
        }

        return overlayPath?.length > 0 ? `${poolPath}/overlays/${overlayPath}` : '';
    }

    private get overlayImageDouche() {
        let overlayPath = '';

        //  Zwei Beckenseiten 2m breit
        // Check additional options
        this.result.topics.forEach((topic) => {
            topic.options.forEach((option: Option) => {
                if (option.id === 64 || option.id === 65) {
                    overlayPath = `${poolPath}/overlays/Dusch.png`;
                }
            });
        });

        return overlayPath;
    }

    private get overlayImageStone() {
        let overlayPath = '';

        //  Zwei Beckenseiten 2m breit
        // Check additional options
        this.result.topics.forEach((topic) => {
            topic.options.forEach((option: Option) => {
                if (option.id === 58 && option.value !== 0) {
                    overlayPath = `${poolPath}/overlays/Schwimmteich_Stein_Overlay.png`;
                }
            });
        });

        return overlayPath;
    }

    private get title() {
        if (this.readyToVisualize) {
            return 'Ihr Naturbad';
        }

        return (
            'Ihr ' +
            this.result.product.title +
            ' (' +
            this.result.dimension.width +
            'x' +
            this.result.dimension.length +
            'm)'
        );
    }

    get readyToVisualize() {
        return (
            R.isEmpty(this.result.product) ||
            R.isEmpty(this.result.constructionMethod) ||
            R.isEmpty(this.result.dimension) ||
            R.isEmpty(this.result.material)
        );
    }
}
