
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action, Mutation, State } from 'vuex-class';
import { mapGetters } from 'vuex';

import Intro from '../configuration/1_intro/Intro.vue';
import Selection from '../configuration/2_selection/Selection.vue';
import Addons from '../configuration/3_addons/Addons.vue';
import Result from '../configuration/4_result/Result.vue';
import { Product } from '@/models/configurator/product';
import { ConstructionMethod } from '@/models/configurator/constructionMethod';
import { SnackbarProgrammatic as Snackbar } from 'buefy';

import * as R from 'ramda';

@Component({
    name: 'WizardConfigurator',
    components: {
        Intro,
        Selection,
        Addons,
        Result,
    },
})
export default class WizardConfigurator extends Vue {
    @Getter('configuratorResult/selectedProduct') private selectedProduct: Product;
    @Getter('configuratorResult/selectedConstructionMethod') private selectedConstructionMethod: ConstructionMethod;
    @Getter('configuratorProduct/products') private products: Product[];

    @Action('configuratorProduct/fetchProducts') private fetchProducts;
    @Action('configuratorConstructionMethod/fetchConstructionMethods') private fetchConstructionMethods;
    @Action('configuratorDimension/fetchDimensions') private fetchDimensions;
    @Action('configuratorMaterial/fetchMaterials') private fetchMaterials;
    @Action('configuratorTopic/fetchAddonTopics') private fetchAddonTopics;
    @Action('configuratorForm/fetchForms') private fetchForms;

    get selectionDisabled() {
        return R.isEmpty(this.selectedProduct) || R.isEmpty(this.selectedConstructionMethod);
    }

    private async mounted() {
        await this.fetchProducts();
        await this.fetchConstructionMethods();
        await this.fetchDimensions();
        await this.fetchMaterials();
        await this.fetchAddonTopics();
        await this.fetchForms();

        if (this.$route.query.hasOwnProperty('proposal')) {
            this.currentTab = 1;
        }
    }

    get currentTab() {
        return this.$store.state.configuratorWizard.currentTab;
    }

    set currentTab(value: number) {
        this.$store.commit('configuratorWizard/setCurrentTab', value);
    }
}
