
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { mapGetters } from 'vuex';
import { resultWebservice } from '@/webservices/preAnalysis/result';
import { User } from '@/models/common/user';
import { Result } from '@/models/preAnalysis/result';
import { ProposalMatch } from '@/models/preAnalysis/proposalmatch';
import Proposal from '@/components/preAnalysis/analysis/proposal/Proposal.vue';
import { Topic } from '@/models/preAnalysis/topic';
import { QuestionBase } from '@/components/preAnalysis/analysis/questions/QuestionBase';

@Component({
    components: {
        Proposal,
    },
})
export default class EmailTheResults extends Vue {
    // Data
    public genBtnOptions = {
        text: 'Als PDF speichern',
        url: '#',
        target: '',
        icon: 'floppy-o',
        loading: false,
        generated: false,
    };

    public errorMsg: string = '';

    protected disabled: boolean = false;
    protected isModalActive: boolean = false;
    protected emailSent: boolean = false;
    protected sendingEmail: boolean = false;

    @Getter('preAnalysisResult/result') private result: Result;
    @Getter('preAnalysisResult/proposalMatches') private proposalMatches: ProposalMatch[];
    @Getter('preAnalysisTopic/topics') private topics: Topic[];

    @Getter('account/currentUser') private currentUser: User;
    @Mutation('account/setCurrentUser') private setCurrentUser;

    private generatePDF() {
        if (this.genBtnOptions.generated) {
            return 0;
        }
        this.storeTheResultsAndGenPdf(false);
    }

    private toConfigurator() {
        this.$router.push({
            path: '/configurator',
            query: {
                proposal: this.proposalMatches[0].title,
            },
        });
    }

    private storeTheResultsAndGenPdf(followUp: boolean) {
        // If followUp is true Validate the user info
        if ((followUp && !this.validateUserInput()) || this.genBtnOptions.loading) {
            return 0;
        }

        this.genBtnOptions.text = 'PDF wird generiert..';
        this.genBtnOptions.icon = '';
        this.genBtnOptions.loading = true;
        this.sendingEmail = true;

        const user: User = {
            id: 0,
            role_id: 0,
            firstname: this.result.firstname,
            lastname: this.result.lastname,
            email: this.result.email,
            phone_number: this.result.phone_number,
            password: '',
            new_password: '',
        };

        this.result.proposal_matches = JSON.parse(JSON.stringify(this.proposalMatches));

        this.setCurrentUser(user);

        resultWebservice.sendResult(this.result).then((response) => {
            setTimeout(() => {
                this.sendingEmail = false;
                this.emailSent = true;
                this.editGenBtnOptions(response.data.fileName, user.email);
            }, 1000);
        });
    }

    private editGenBtnOptions(fileName, user_email) {
        // tslint:disable-next-line:max-line-length
        this.genBtnOptions.url =
            process.env.VUE_APP_API_URL + 'pre_analysis/v1/' + 'Salamander-Naturbad-Auswertung?filename=' + fileName;

        if (user_email !== '') {
            this.genBtnOptions.url += '&email=' + user_email;
        } else {
            this.genBtnOptions.url += '&email=guests';
        }

        this.genBtnOptions.target = '_blank';
        this.genBtnOptions.loading = false;
        this.genBtnOptions.generated = true;
        this.genBtnOptions.text = 'Download';
        this.genBtnOptions.icon = 'download';
    }

    private toggleInfoModal(event) {
        this.isModalActive = !this.isModalActive;
        this.emailSent = false;
        this.errorMsg = '';
    }

    private validateUserInput() {
        if (this.result.firstname === '') {
            // this.errorMsg = 'Plaese enter your Name';
            this.errorMsg = 'firstname';
            return 0;
        } else if (this.result.lastname === '') {
            this.errorMsg = 'lastname';
            return 0;
        } else if (this.result.phone_number === '') {
            this.errorMsg = 'phone_number_empty';
            return 0;
        } else if (!this.validateEmail(this.result.email)) {
            this.errorMsg = 'email';
            return 0;
            /*
                // Currently disabled. Many swiss people are not used to add the number with +41 and use spaces often.
                } else if (!this.validatePhoneNumber(this.phone_number)) {
                this.errorMsg = 'phone_number';
                return 0;
                */
        } else {
            return true;
        }
    }

    private validateEmail(email) {
        // tslint:disable-next-line:max-line-length
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    private validatePhoneNumber(phone_number) {
        const re = /^(?:(?:|0{1,2}|\+{0,2})41(?:|\(0\))|0)([1-9]\d)(\d{3})(\d{2})(\d{2})$/;
        return re.test(phone_number.toLowerCase());
    }

    private reset() {
        // Reset the result
        this.$store.commit('preAnalysisResult/reset');

        // Set the active topic to be the first one
        this.$store.commit('preAnalysisTopic/setActiveTopic', this.topics[0]);

        // Reset the go later to photos
        this.$store.commit('preAnalysisWizard/setLaterGoToPhotos', false);
        this.$store.commit('preAnalysisWizard/setLaterGoToResults', false);

        // Switch the tab to first
        this.$store.commit('preAnalysisWizard/setCurrentTab', 0);
    }
}
