
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Result } from '@/models/configurator/result';

@Component({
    name: 'ResultDetails',
})
export default class ResultDetails extends Vue {
    @Getter('configuratorResult/result') private result: Result;
    // @Prop() private result: Result;
}
