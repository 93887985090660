
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { mapGetters } from 'vuex';
import { ProposalMatch } from '@/models/preAnalysis/proposalmatch';
import { photoWebservice } from '@/webservices/preAnalysis/photo';
import { Photo } from '@/models/preAnalysis/photo';

@Component
export default class ProposalModal extends Vue {
    protected photos: Photo[] = new Array<Photo>();
    // Data
    protected swiperOption = {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: false,
        speed: 600,
        parallax: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };

    // Props
    @Prop() private isModalActive: boolean;
    @Prop() private activeProposal;

    // Mounted is called initially
    private mounted() {
        this.loadAllPhotos();
    }

    private loadAllPhotos() {
        photoWebservice
            .getAllPhotos()
            .then((photos) => {
                this.photos = photos.filter((photo) => {
                    return photo.proposal_id === this.activeProposal.proposal_id;
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private toggleInfoModal() {
        this.$emit('toggleInfoModal');
    }

    private getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}
