
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'ConfirmModal',
    components: {},
})
export default class ConfirmModal extends Vue {
    @Prop({
        default: 'Bist du sicher?',
        type: String,
    })
    private confirmText: string;

    private confirm() {
        this.$emit('toggleConfirmModal', true);
    }

    private toggleModal() {
        this.$emit('toggleConfirmModal', false);
    }
}
