
import { QuestionBase } from './QuestionBase';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { mapGetters } from 'vuex';
import { Topic } from '@/models/preAnalysis/topic';
import { Result } from '@/models/preAnalysis/result';

@Component({})
export default class QuestionsMobile extends QuestionBase {
    @Getter('preAnalysisTopic/activeTopic') private activeTopic: Topic;
    @Getter('preAnalysisResult/result') private result: Result;

    // Data
    private isMobileScreen: boolean = false;

    private goNextTopic() {
        const currentTopicIndex = this.activeTopic.id - 1;
        this.$store.commit('preAnalysisTopic/setActiveTopic', this.topics[currentTopicIndex + 1]);
    }

    private goPreviousTopic() {
        const currentTopicIndex = this.activeTopic.id - 1;
        this.$store.commit('preAnalysisTopic/setActiveTopic', this.topics[currentTopicIndex - 1]);
    }
}
