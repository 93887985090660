
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Topic } from '@/models/configurator/topic';

import TopicBox from '@/components/configurator/helpers/topic/TopicBox.vue';
import OfferMinimal from '@/components/configurator/offer/OfferMinimal.vue';
import PoolVisualizerImproved from '@/components/configurator/visuals/PoolVisualizerImproved.vue';
import ResetButton from '@/components/configurator/helpers/ResetButton.vue';
import CreateTopicModalButton from '@/components/editor/topic-editor/CreateTopicModalButton.vue';
import draggable from 'vuedraggable';
import { User } from '@/models/common/user';
import OfferAddonsOptions from '@/components/configurator/offer/OfferAddonsOptions.vue';

@Component({
    name: 'Addons',
    components: {
        OfferAddonsOptions,
        TopicBox,
        CreateTopicModalButton,
        OfferMinimal,
        PoolVisualizerImproved,
        ResetButton,
        draggable,
    },
})
export default class Addons extends Vue {
    @Action('editorTopic/setIsReOrderActive') private setIsReOrderActive;
    @Action('scope/fetchScopes') private fetchScopes;
    @Getter('editorTopic/isReOrderActive') private isReOrderActive: number;

    @Action('configuratorTopic/swapAddonTopics') private swapAddonTopics;
    @Getter('configuratorTopic/addonTopics') private addonTopics: Topic[];
    @Action('configuratorTopic/setAddonTopics') private setAddonTopics;
    @Getter('account/currentUser') private current_user: User;
    @Getter('account/isLoggedIn') private isLoggedIn: boolean;

    private mounted() {
        this.fetchScopes();
        this.setIsReOrderActive(false);
    }

    private toggleReOrder() {
        this.setIsReOrderActive(!this.isReOrderActive);
    }

    private reOrderUp(index) {
        this.swapAddonTopics({
            sourceIndex: index,
            targetIndex: index - 1,
        });
    }

    private reOrderDown(index) {
        this.swapAddonTopics({
            sourceIndex: index,
            targetIndex: index + 1,
        });
    }

    private goNext() {
        this.$store.commit('configuratorWizard/setCurrentTab', 3);
    }

    private get topics() {
        return this.addonTopics;
    }

    private set topics(value) {
        this.setAddonTopics(value);
    }
}
